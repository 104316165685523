import React, { useEffect, useState } from 'react'
import styles from './Testimonials.module.css'
import Slider from 'react-slick';
import anita from '../../Assets/quotes/anita.jpg'
import comfort from '../../Assets/quotes/comfort.jpg'
import emmanuel from '../../Assets/quotes/emmanuel.jpg'
import grace from '../../Assets/quotes/grace.jpg'
import igoje from '../../Assets/quotes/igoje.jpg'
import john from '../../Assets/quotes/john.jpg'
import joseph from '../../Assets/quotes/joseph.jpeg'
import sufyan from '../../Assets/quotes/sufyan.jpg'
import yakub from '../../Assets/quotes/yakub.jpg'

const Testimonials = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const testimonyData = [
        {
          name: "Joseph Awara",
          text: `Unlike the millions of boring learning platforms around, this particular learning game is something totally different and special. It was designed with the intention of bringing fun to you while you progress through the different learning levels and challenges in the game. `,
          avatar: joseph,
        },
        {
          name: "Yakub Abdulmalik",
          text: `The employment bootcamp live sessions have been very exciting. They are basically cheat code sessions. These insights are powerful tools to keep in your arsenal as you tackle the ups and downs of job search. My expectations are always exceeded and leave with more than I thought I would gain. `,
          avatar: yakub,
        },
        {
          name: "Emmanuel Damilola Arowolo",
          text: `This program aligns perfectly with my ongoing journey of self-improvement and development. I'm incredibly thankful for this opportunity and genuinely excited about completing the other seven levels of the program, as each step promises to bring fresh insights and tools to help me flourish in my career.`,
          avatar: emmanuel,
        },
        {
          name: "John Nwachukwu Kanu",
          text: `Level 4 talked about writing rewarding CVs and Cover Letters. This particular level made me see the major reason why I haven't been selected for any role, despite my many applications. We got to understand the major things employers or recruiters look out for in the recruiting process.`,
          avatar: john,
        },
        {
          name: "Sufyan Ibrahim",
          text: `As I reflect on my experience, I'm amazed by how much I've grown. This phase of the bootcamp has not only equipped me with tangible skills but has also boosted my confidence. I am eager to implement these strategies as I take the next steps in my professional journey.`,
          avatar: sufyan,
        },
        {
          name: "Grace Chinadindu Kalu",
          text: `One of the reasons why I haven't posted is because I didn't know how to put myself out there as a Creative. Today, I'm here to show up and I won't do this, without acknowledging the force behind the new me. The Employment Bootcamp has been a blessing!`,
          avatar: grace,
        },
        {
            name: "Anita Imogu",
            text: `I'm genuinely amazed at the insights I've gained so far. Can't wait to see how this knowledge will transform my career and life. Kudos to The Employment Bootcamp team for curating such impactful content!`,
            avatar: anita,
        },    
        {
            name: "Igoje Michael",
            text: `This journey of self-discovery has shaped my life's perspective, my thought process and my worldview with everything consciously centering around my values.`,
            avatar: igoje,
        },
    
        ];
      
          useEffect(() => {
              const interval = setInterval(() => {
                  setCurrentSlide((prevSlide) => (prevSlide === testimonyData.length - 1 ? 0 : prevSlide + 1));
              }, 5000); // Change the interval duration as needed (currently set to 5 seconds)
              return () => clearInterval(interval);
          }, [testimonyData.length]);
      
          const settings = {
              dots: false,
              infinite: true,
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: true,
              speed: 2000,
              autoplaySpeed: 5000, // Adjusted to match the interval duration
              beforeChange: (current, next) => setCurrentSlide(next),
               responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
          };      

  return (
    <section className={styles.testimonyCon} id='testimonials'>
        <div className="container p-4">
            <div className="row">
                <div className={`col-md-12 p-5 position-relative ${styles.testimony}`}>
                    <svg
                    className='position-absolute'
                    xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9295 20.9499C13.9016 20.2356 16.7637 20.2823 19.7784 20.1967C20.3671 20.1801 20.8566 20.632 20.8743 21.2057C20.8921 21.7796 20.4274 22.2586 19.8387 22.2756C16.9801 22.3567 14.2598 22.2912 11.4367 22.9691C10.8657 23.1064 10.2876 22.765 10.1493 22.2077C10.0074 21.6507 10.3585 21.0868 10.9295 20.9499Z" fill="#161B23"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.16912 14.8815C9.70172 10.0355 15.5324 5.46945 21.4198 2.27801C21.9341 1.9997 22.5832 2.18098 22.8669 2.68285C23.1506 3.18471 22.9662 3.81828 22.4519 4.09659C16.7596 7.18266 11.1203 11.5986 6.74022 16.2846C6.34302 16.7081 5.66912 16.7375 5.23642 16.3504C4.80372 15.9633 4.77182 15.3051 5.16912 14.8815Z" fill="#161B23"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.216616 13.6539C-0.531784 9.22171 0.745014 4.65326 2.55021 0.62307C2.78791 0.0972884 3.41562 -0.142562 3.95122 0.0872304C4.49032 0.317368 4.73502 0.93119 4.50092 1.45698C2.85532 5.12741 1.63522 9.27855 2.31622 13.3153C2.41191 13.8817 2.01822 14.4172 1.44011 14.5104C0.862015 14.604 0.312315 14.2199 0.216616 13.6539Z" fill="#161B23"/>
                    </svg>
                    <h2>Testimonials</h2>
                </div>
                <div className="col-md-12 pt-3 mb-5">
                    <div className={`row ${styles.testimonyCard}`}>
                      <Slider {...settings}>
                        {testimonyData.map((td, index) => (
                          <div className={`col-md-5 mb-3 mb-md-0`}>
                            <div className={`card ${styles.testimonyCardCon}`}>
                              <div className="row">
                                <div className="col-lg-2 col-md-3">
                                  <img src={td.avatar} alt={td.name} />
                                </div>
                                <div className="col-lg-10 col-md-9">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <h4>{td.name}</h4>
                                    </div>
                                    <div className="col-md-12">
                                      <p>{td.text}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                </div>      
            </div>
        </div>
    </section>
  )
}

export default Testimonials