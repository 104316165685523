import React, { useEffect, useRef } from 'react';
import styles from './Impact.module.css';

const Impact = () => {

  const impactRef = useRef(null);

useEffect(() => {
  let observer;
  let animationFrameId;

  function animateValue(id, start, end, duration, suffix = '') {
    return new Promise((resolve) => {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const currentValue = Math.floor(progress * (end - start) + start);
        const element = document.getElementById(id);
        if (element) {
          element.innerText = currentValue.toLocaleString() + suffix;
        }
        if (progress < 1) {
          animationFrameId = requestAnimationFrame(step);
        } else {
          resolve();
        }
      };
      animationFrameId = requestAnimationFrame(step);
    });
  }

  function resetValues() {
    const youthsEngaged = document.getElementById('youthsEngaged');
    const countriesReached = document.getElementById('countriesReached');
    const completionRate = document.getElementById('completionRate');
    const leadersInvolved = document.getElementById('leadersInvolved');

    if (youthsEngaged) youthsEngaged.innerText = '0k+';
    if (countriesReached) countriesReached.innerText = '0';
    if (completionRate) completionRate.innerText = '0%';
    if (leadersInvolved) leadersInvolved.innerText = '0+';
  }

  function handleIntersection(entries) {
    const entry = entries[0];
    if (entry.isIntersecting) {
      resetValues();
      const animateSequence = async () => {
        await animateValue('youthsEngaged', 0, 960, 1000, 'k+');
        await animateValue('countriesReached', 0, 25, 1000);
        await animateValue('completionRate', 0, 70, 1000, '%');
        await animateValue('leadersInvolved', 0, 100, 1000, '+');
      };
      animateSequence();
    }
  }

  observer = new IntersectionObserver(handleIntersection, {
    threshold: 0.1,
  });

  if (impactRef.current) {
    observer.observe(impactRef.current);
  }

  return () => {
    if (impactRef.current) {
      observer.unobserve(impactRef.current);
    }
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }
    observer = null;
  };
}, []);

  return (
    <section className={`container pt-5 ${styles.impactCon}`} id='impact' ref={impactRef}>
      <div className={`row align-items-center ${styles.infoCon}`}> 
        <div className={`col-md-12 text-center position-relative ${styles.impactHead}`}>
          <svg 
            className='position-absolute'
            xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0705 21.4499C9.0984 20.7356 6.23627 20.7823 3.22162 20.6967C2.63287 20.6801 2.1434 21.132 2.12567 21.7057C2.10794 22.2796 2.57256 22.7586 3.16131 22.7756C6.01991 22.8567 8.74017 22.7912 11.5633 23.4691C12.1343 23.6064 12.7124 23.265 12.8507 22.7077C12.9926 22.1507 12.6415 21.5868 12.0705 21.4499Z" fill="#161B23"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.8309 15.3815C13.2983 10.5355 7.46761 5.96945 1.58016 2.77801C1.0659 2.4997 0.416831 2.68098 0.133099 3.18285C-0.150634 3.68471 0.0338085 4.31828 0.548072 4.59659C6.24045 7.68266 11.8797 12.0986 16.2598 16.7846C16.657 17.2081 17.3309 17.2375 17.7636 16.8504C18.1963 16.4633 18.2282 15.8051 17.8309 15.3815Z" fill="#161B23"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.7834 14.1539C23.5318 9.72171 22.255 5.15326 20.4498 1.12307C20.2121 0.597288 19.5844 0.357438 19.0488 0.58723C18.5097 0.817368 18.265 1.43119 18.4991 1.95698C20.1447 5.62741 21.3648 9.77855 20.6838 13.8153C20.5881 14.3817 20.9818 14.9172 21.5599 15.0104C22.138 15.104 22.6877 14.7199 22.7834 14.1539Z" fill="#161B23"/>
          </svg>
          <h2>Impact</h2>
        </div>
        <div className="col-12 pt-4">
          <div className={`row text-center ${styles.impactStory}`}>
            <div className="col-md-3 p-5">
              <h2 id="youthsEngaged">960k+</h2>
              <span>Youths Engaged</span>
            </div>
            <div className="col-md-3 p-5">
              <h2 id="countriesReached">23</h2>
              <span>Countries Reached</span>
            </div>
            <div className="col-md-3 p-5">
              <h2 id="completionRate">70%</h2>
              <span>Program Completion Rate</span>
            </div>
            <div className="col-md-3 p-5">
              <h2 id="leadersInvolved">100+</h2>
              <span>Global Leaders Involved</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Impact;