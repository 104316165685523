import React from 'react'
import styles from './Solutions.module.css'
import fund from '../../Assets/Logos/fundIcon1.svg'
import teb from '../../Assets/Logos/tebIcon1.svg'
import network from '../../Assets/Logos/networkIcon1.svg'
import job from '../../Assets/Logos/jobclubIcon1.svg'
import { Link } from 'react-router-dom'

const Solutions = () => {
  return (
    <section id='solutions'>
        <div className="container py-4 px-4">
            <div className="row align-items-center justify-content-around">
                <div className={`col-12 text-center position-relative pt-5 ${styles.solHead}`}>
                    <svg 
                    className='position-absolute'
                    xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0705 21.4499C9.0984 20.7356 6.23627 20.7823 3.22162 20.6967C2.63287 20.6801 2.1434 21.132 2.12567 21.7057C2.10794 22.2796 2.57256 22.7586 3.16131 22.7756C6.01991 22.8567 8.74017 22.7912 11.5633 23.4691C12.1343 23.6064 12.7124 23.265 12.8507 22.7077C12.9926 22.1507 12.6415 21.5868 12.0705 21.4499Z" fill="#161B23"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8309 15.3815C13.2983 10.5355 7.46761 5.96945 1.58016 2.77801C1.0659 2.4997 0.416831 2.68098 0.133099 3.18285C-0.150634 3.68471 0.0338085 4.31828 0.548072 4.59659C6.24045 7.68266 11.8797 12.0986 16.2598 16.7846C16.657 17.2081 17.3309 17.2375 17.7636 16.8504C18.1963 16.4633 18.2282 15.8051 17.8309 15.3815Z" fill="#161B23"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7834 14.1539C23.5318 9.72171 22.255 5.15326 20.4498 1.12307C20.2121 0.597288 19.5844 0.357438 19.0488 0.58723C18.5097 0.817368 18.265 1.43119 18.4991 1.95698C20.1447 5.62741 21.3648 9.77855 20.6838 13.8153C20.5881 14.3817 20.9818 14.9172 21.5599 15.0104C22.138 15.104 22.6877 14.7199 22.7834 14.1539Z" fill="#161B23"/>
                    </svg>
                    <h2>
                        Solutions
                    </h2>
                </div>
                <div className="col-12 pt-4">
                    <div className="row">
                        <div className={`col-sm-6 mb-3 mb-sm-3 ${styles.solCon}`} id='teb'>
                            <div className={`card h-100 ${styles.solInfo}`}>
                              <div className="card-body">
                                <div className="col-12 pt-4">
                                    <h5>THE EMPLOYMENT BOOTCAMP</h5>
                                </div>
                                <div className="col-12 py-4 text-center">
                                <img src={teb} alt="teb" width={40} />
                                </div>
                                <div className="col-12 px-3">
                                    <p>
                                    The Employment Bootcamp is nn intervention designed to deliver a single outcome - decent, meaningful work for young Africans. Underpinned by a learning game, participants learn the frameworks they need to:
                                    <ul className='text-start'>
                                      <li>
                                        Get clarity and direction for their next move.
                                      </li>
                                      <li>
                                        Start their career on the right foundation or to course correct if needed.
                                      </li>
                                      <li>
                                        Create a game plan and gain access to global job opportunities.
                                      </li>
                                      <li>
                                        Create their own opportunities and go into the future with confidence.
                                      </li>
                                    </ul>
                                    </p>
                                <div className={`pt-md-2 text-center ${styles.teBtn}`}>
                                  <Link to={"http://bootcamp.naomilucas.xyz/"}>
                                    <button>
                                        Apply
                                    </button>
                                  </Link>
                                </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className={`col-sm-6 mb-3 mb-sm-3 ${styles.solCon}`} id='opportunity-fund'>
                            <div className={`card h-100 ${styles.solInfo}`}>
                              <div className="card-body">
                                <div className="col-12 pt-4">
                                    <h5>Opportunity Fund</h5>
                                </div>
                                <div className="col-12 py-4 text-center">
                                <img src={fund} alt="fund" width={40} />
                                </div>
                                <div className="col-12 pb-md-3 px-3">
                                    <p>
                                    There are wicked problems truncating the efforts of young people who are striving to create their own opportunities. Issues such as availability of smart devices, data and access to targeted interventions such as The Employment Bootcamp. Through the fund, we are committed to tackling these challenges.
                                    <br />
                                    Our goal is to secure 250,000 dollars to cover cost of participation in the bootcamp, affordable smart devices, airtime and job club subscription for 5,000 young people. With your contribution, we can accelerate the absorption of young people into the workforce.
                                    </p>
                                <div className={`pt-md-5 text-center ${styles.contribute}`}>
                                  <Link to={"https://paystack.com/pay/gof1"}>
                                    <button>
                                        Contribute
                                    </button>
                                  </Link>
                                </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className={`col-sm-6 mb-3 mb-sm-0 ${styles.solCon}`} id='job-club'>
                            <div className={`card h-100 ${styles.solInfo}`}>
                              <div className="card-body">
                                <div className="col-12 pt-4">
                                    <h5>Job Club</h5>
                                </div>
                                <div className="col-12 py-4 text-center">
                                <img src={job} alt="job" width={40} />
                                </div>
                                <div className="col-12 px-3">
                                    <p>
                                    Graduatepro Job Club is a targeted, project-based accountability program that helps unemployed youths find meaningful work within 90 days. Dedicated Resident Recruiters, Mentors and Success Managers provide personalised support to club members. They help review their CVs. Cover Letters and Portfolios, prepare them for interviews, scout for opportunities, market them to recruiters, and provide on-demand mentorship and coaching services.
                                    </p>
                                <div className='pt-md-5 text-center'>
                                  <Link to={"/job-club"}>
                                    <button>
                                        Apply
                                    </button>
                                  </Link>
                                </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className={`col-sm-6 mb-3 mb-sm-0 ${styles.solCon}`} id='network'>
                            <div className={`card h-100 ${styles.solInfo}`}>
                              <div className="card-body">
                                <div className="col-12 pt-4">
                                    <h5>Network</h5>
                                </div>
                                <div className="col-12 py-4 text-center">
                                <img src={network} alt="network" width={40} />
                                </div>
                                <div className="col-12 px-3">
                                    <p>
                                    The Graduatepro Career Network is a global community of young professionals fostering purpose and career advancement by catalysing opportunities for decent work and societal impact for its members.
                                    It is a safe space for people to assume autonomy and agency over their lives, make informed decisions, define what success means to them outside of societal constructs and fail forward without judgment; making strides towards growth one step at a time.
                                    <br />
                                    <b><em>
                                    Are you a young professional looking for a thriving community of like minds? Then join us.
                                    </em></b>
                                    </p>
                                <div className='text-center'>
                                  <Link to={"/network"}>
                                    <button>
                                        Join
                                    </button>
                                  </Link>
                                </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Solutions