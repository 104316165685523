import RootComponent from "./Components/RootComponent";
import { Route, Routes } from "react-router-dom";
import Contacts from "./Components/Contacts/Contacts";
import Partner from "./Components/Partner/Partner";
import JobClub from "./Components/Network/JobClub";
import Network from "./Components/Network/Network";
import ErrorPage from "./Components/404/ErrorPage";
import ScrollToTop from "./Components/ScrollToTop";
import "./App.css";

function App() {
  return (
    <>
      <ScrollToTop paths={["/job-club", "/network"]} />
      <Routes>
        <Route path="/" element={<RootComponent />} />
        <Route path="/contact" element={<Contacts />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/job-club" element={<JobClub />} />
        <Route path="/network" element={<Network />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
