import React from 'react'
import errorPage from '../../Assets/Logos/404page.svg'
import { LuArrowUpRight } from "react-icons/lu";
import styles from './ErrorPage.module.css'
import PageNav from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const ErrorPage = () => {
  return (
    <> 
        <PageNav />
        <section className={styles.errContainer}>
            <div className={`container ${styles.errorCon}`}>
                <div className={`row justify-content-center ${styles.content}`}>
                    <div className="col-md-12 text-center">
                        <img src={errorPage} alt="404 page" />
                    </div>
                    <div className="col-md-12 text-center">
                        <h1>SORRY! PAGE NOT FOUND</h1>
                        <p>Our team is diligently working to establish necessary structures. In the meantime, please</p>
                    </div>
                    <a href="/contact" className='text-center'>
                        <LuArrowUpRight />
                        Contact Us
                    </a>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}

export default ErrorPage