import React from 'react'
import PageNav from '../Navbar/Navbar'
import styles from './Network.module.css'
import Footer from '../Footer/Footer'

const JobClub = () => {
  return (
    <>
        <PageNav />
        <section className={styles.jobSec}>
            <div className={`container ${styles.jobCon}`}>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Graduatepro Job Club only takes applications from participants of our programs. To learn more and apply, visit 
                            <br />
                            <a href="https://bootcamp.naomilucas.xyz/" target='_blank'>www.bootcamp.naomilucas.xyz.</a>
                            <br />
                            <br />
                            Thank you.
                        </h2>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}

export default JobClub