import React from 'react';
import styles from './OurServices.module.css';
import advisor from '../../Assets/facultyBack/oServices/Untitled1-removebg-preview.png'
import designer from '../../Assets/facultyBack/oServices/Untitled2-removebg-preview.png'
import curator from '../../Assets/facultyBack/oServices/Untitled-removebg-preview.png'

const OurService = () => {
    //service list
    const services = [
        {
            header: "WE ADVICE CORPORATES AND GOVERNMENTS",
            body: "We understand the business of youth. We work with the public and private sector to design, develop and implement youth-focused initiatives. By leveraging our experience, you can dramatically improve the outcomes of your programs, campaigns or interventions.",
            image: advisor,
            alt: "government advisor",
            hoverTag: "card1"
        },
        {
            header: "WE CURATE FAIRS AND MASTERCLASSES",
            body: "We curate carefully tailored career fairs, masterclasses and events to help you find, attract and maintain a healthy pipeline of world-class talent thereby reducing time to fill and improving the quality of candidates.",
            image: curator,
            alt: "masterclass curator",
            hoverTag: "card2"
        },
        {
            header: "WE DESIGN LEARNING EXPERIENCES",
            body: "We are bullish about bridging the gap between the demand for work-ready talent and the supply of decent, meaningful work. We design experiences that equip young people with the skills and tools they need to thrive in a global marketplace.",
            image: designer,
            alt: "learning experiences designer",
            hoverTag: "card3"
        }
    ];

    return (
        <div className={styles.services} id='services'>
            <div className="container p-4">
                <div className="row">
                    <div className={`col-12 text-center pt-5 position-relative ${styles.serviceHeader}`}>
                        <svg 
                        className='position-absolute'
                        xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0705 21.4499C9.0984 20.7356 6.23627 20.7823 3.22162 20.6967C2.63287 20.6801 2.1434 21.132 2.12567 21.7057C2.10794 22.2796 2.57256 22.7586 3.16131 22.7756C6.01991 22.8567 8.74017 22.7912 11.5633 23.4691C12.1343 23.6064 12.7124 23.265 12.8507 22.7077C12.9926 22.1507 12.6415 21.5868 12.0705 21.4499Z" fill="#161B23"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8309 15.3815C13.2983 10.5355 7.46761 5.96945 1.58016 2.77801C1.0659 2.4997 0.416831 2.68098 0.133099 3.18285C-0.150634 3.68471 0.0338085 4.31828 0.548072 4.59659C6.24045 7.68266 11.8797 12.0986 16.2598 16.7846C16.657 17.2081 17.3309 17.2375 17.7636 16.8504C18.1963 16.4633 18.2282 15.8051 17.8309 15.3815Z" fill="#161B23"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7834 14.1539C23.5318 9.72171 22.255 5.15326 20.4498 1.12307C20.2121 0.597288 19.5844 0.357438 19.0488 0.58723C18.5097 0.817368 18.265 1.43119 18.4991 1.95698C20.1447 5.62741 21.3648 9.77855 20.6838 13.8153C20.5881 14.3817 20.9818 14.9172 21.5599 15.0104C22.138 15.104 22.6877 14.7199 22.7834 14.1539Z" fill="#161B23"/>
                        </svg>
                        <h2>OUR SERVICES</h2>
                    </div>
                    <div className="col-12 pt-3">
                        <div className="row justify-content-evenly">
                            {services.map((service, index) => (
                                <div class={`card ${styles.cardBack} ${styles[service.hoverTag]}`}>
                                    <img src={service.image} class="card-img-top" alt={service.alt} />
                                    <div class={`card-body text-center ${styles.serviceTitle}`}>
                                      <h5 class="card-title">{service.header}</h5>
                                      <p class="card-text pt-3">{service.body}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurService;
