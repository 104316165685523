import React, { useRef } from "react";
import { FaLinkedinIn, FaFacebook } from "react-icons/fa";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import Slider from "react-slick";
import styles from "./People.module.css";
//import aisha from '../../Assets/facultyBack/aisha1.jpg'
import alex from "../../Assets/facultyBack/alex1.jpg";
import ayo from "../../Assets/facultyBack/ayomide1.jpg";
import chris from "../../Assets/facultyBack/christiana1.jpg";
import daniel from "../../Assets/facultyBack/daniel1.jpg";
import dare from "../../Assets/facultyBack/dare1.jpg";
import ese from "../../Assets/facultyBack/ese1.jpg";
import alado from "../../Assets/facultyBack/Gloria_Alado.png";
import gbadebo from "../../Assets/facultyBack/gbadebo1.jpg";
//import gift from '../../Assets/facultyBack/gift1.jpg'
import glory from "../../Assets/facultyBack/gloria1.jpg";
import ibukun from "../../Assets/facultyBack/ibukun1.jpg";
import ijeoma from "../../Assets/facultyBack/ijeoma1.jpg";
import jibril from "../../Assets/facultyBack/jibril1.jpg";
import kathleen from "../../Assets/facultyBack/kathleen1.jpg";
import maryam from "../../Assets/facultyBack/maryam1.jpg";
import mayowa from "../../Assets/facultyBack/mayowa1.jpg";
import mcfoy from "../../Assets/facultyBack/mcfoy1.jpg";
import obinna from "../../Assets/facultyBack/obinna1.jpg";
import olaronke from "../../Assets/facultyBack/olaronke1.jpg";
import olumide from "../../Assets/facultyBack/olumide1.jpg";
import olusola from "../../Assets/facultyBack/olusola1.jpg";
//import omale from '../../Assets/facultyBack/omale1.jpg'
import osho from "../../Assets/facultyBack/osho1.jpg";
//import oyewo from '../../Assets/facultyBack/oyewo1.jpg'
import peter from "../../Assets/facultyBack/peter1.jpg";
import raquel from "../../Assets/facultyBack/raquel1.jpg";
import segun from "../../Assets/facultyBack/segun1.jpg";
import temidayo from "../../Assets/facultyBack/temidayo1.jpg";
import temitope from "../../Assets/facultyBack/temitope1.jpg";
import toluwase from "../../Assets/facultyBack/toluwase1.jpg";
import toyyib from "../../Assets/facultyBack/toyyib1.jpg";
//import victor from '../../Assets/facultyBack/victor1.jpg'
import folakemi from "../../Assets/facultyBack/folakemi.jpg";
import fortune from "../../Assets/facultyBack/fortune1.jpg";

//import anna from '../../Assets/Team/onaye.jpg'
//import victor from '../../Assets/Team/victor.jpg'
//import sodiq from '../../Assets/Team/sodiq.jpg'
//import emmanuel from '../../Assets/Team/emmanuel.png'
//import peace from '../../Assets/Team/nduagu.jpg'

const People = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const facilitatorSliderRef = useRef(null);
  //const assessorSliderRef = useRef(null);

  const nextFacilitatorSlide = () => {
    facilitatorSliderRef.current.slickNext();
  };

  const prevFacilitatorSlide = () => {
    facilitatorSliderRef.current.slickPrev();
  };

  //const nextAssessorSlide = () => {
  //  assessorSliderRef.current.slickNext();
  //};
  //
  //const prevAssessorSlide = () => {
  //  assessorSliderRef.current.slickPrev();
  //};

  const faculty = [
    {
      name: "Peter Kwakpovwe",
      image: peter,
      url: "https://www.linkedin.com/in/",
      title: "Data Scientist, Northsnow",
    },
    //{
    //  name: "J’odie",
    //  image: jodie,
    //  url: "https://www.linkedin.com/in/tochukwu-macfoy-05947b103/",
    //  title: "Musician, Coordinator, Chinua Children Care Foundation",
    //},
    {
      name: "Kathleen Ndongmo",
      image: kathleen,
      url: "https://www.linkedin.com/in/",
      title: "Digital Content Manager, Viamo",
    },

    {
      name: "Toluwase Olaniyan",
      image: toluwase,
      url: "https://www.linkedin.com/in/",
      title: "CEO, Worden",
    },

    {
      name: "Ese Oraka",
      image: ese,
      url: "https://www.linkedin.com/in/",
      title: "Founding Partner, Adelphi Innovation Consulting",
    },

    {
      name: "Tochukwu McFoy",
      image: mcfoy,
      url: "https://www.linkedin.com/in/tochukwu-macfoy-05947b103/",
      title: "CEO, Energize Music",
    },
    {
      name: "Mayowa Adegoke",
      image: mayowa,
      url: "https://www.linkedin.com/in/mayowaadegoke/",
      title: "Middle East Correspondent, Channels TV",
    },

    {
      name: "Alex Ohai",
      image: alex,
      url: "https://www.linkedin.com/in/alexnnekaohai/",
      title: "Head, HR, 9 Payment Service Bank",
    },
    {
      name: "Olusola John",
      image: olusola,
      url: "https://www.linkedin.com/in/johnolusola/",
      title: "Founder, Careerwise",
    },
    {
      name: "Maryam Aliko Mohammed",
      image: maryam,
      url: "https://www.linkedin.com/in/maryamalikomohammed/",
      title: "CEO, Modalali",
    },
    {
      name: "Ibukun Amosu",
      image: ibukun,
      url: "https://www.linkedin.com/in/ibukunamosu/",
      title: "Founder, The Intern Place",
    },
    {
      name: "Gloria Alado",
      image: alado,
      url: "https://www.linkedin.com/in/gloriangalado/",
      title: "Talent Sourcing Specialist, Pariti",
    },
    {
      name: "Dr. Jibril AbdulMalik",
      image: jibril,
      url: "https://www.linkedin.com/in/jabdulmalik/",
      title: "Founder/CEO, Asido Foundation",
    },
    {
      name: "Segun Lawal",
      image: segun,
      url: "https://www.linkedin.com/in/",
      title: "Founder, Spirit of David",
    },
    {
      name: "Folakemi Onamade",
      image: folakemi,
      url: "https://www.linkedin.com/in/",
      title: "Team Lead, The Employment Bootcamp",
    },
    {
      name: "Toyyib Adelodun",
      image: toyyib,
      url: "https://www.linkedin.com/in/",
      title: "Chief Service Officer, Toyibb Adelodun International",
    },
    {
      name: "Henrietta Ogu",
      image: glory,
      url: "https://www.linkedin.com/in/h",
      title: "Project Manager, Volition Capital",
    },
    {
      name: "Raquel Kasham Daniel",
      image: raquel,
      url: "https://www.linkedin.com/in/raqueldaniel",
      title: "Co-founder, Bambini Africa",
    },
    //{
    //  name: "Akan Ebong",
    //  image: akan,
    //  url: "https://www.linkedin.com/in/",
    //  title: "President/CEO, Amazing Ibom Mushroom Growers",
    //},

    {
      name: "Ijeoma Igbaji",
      image: ijeoma,
      url: "https://www.linkedin.com/in/",
      title: "CEO, White Rabbit NG",
    },
    {
      name: "Dare Joseph Akinfosile",
      image: dare,
      url: "https://www.linkedin.com/in/",
      title: "Health, Safety & Environment (HSE) Manager",
    },
    {
      name: "Olaronke Aribiyi",
      image: olaronke,
      url: "https://www.linkedin.com/in/",
      title: "Business Development Manager, Sparkle",
    },

    {
      name: "Fortune Onyemuwa",
      image: fortune,
      url: "https://www.linkedin.com/in/",
      title: "Lead User Interface and Experience Designer, Kominiti",
    },

    {
      name: "Temitope Akinkunmi",
      image: temitope,
      url: "https://www.linkedin.com/in/temitope-akinkunmi-41188384",
      title: "CEO, Fisibul Networks Int’l",
    },
    {
      name: "Christiana Lamba",
      image: chris,
      url: "https://www.linkedin.com/in/christiana-lamba-911150b3",
      title: "Team Lead, LeMakarios Hub",
    },

    {
      name: "Temidayo Salako",
      image: temidayo,
      url: "https://www.linkedin.com/in/temidayosalako/",
      title: "Head, Marketing, Tunga",
    },
    {
      name: "Olumide Campbell",
      image: olumide,
      url: "https://www.linkedin.com/in/olumide-campbell-5ba50310?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BvfJXSYkOTRuylnt%2B9bx4NA%3D%3D",
      title: "Executive Director, Etu Odi Ltd.",
    },

    {
      name: "Ayodeji Jeremiah",
      image: ayo,
      url: "https://www.linkedin.com/in/ayodejijeremiah1973",
      title: "Senior Management Consultant, SOGA",
    },
    {
      name: "Osho Ademola Joel",
      image: osho,
      url: "https://www.linkedin.com/in/oshoademolajoel",
      title: "CEO, Supretec.com",
    },
    {
      name: "Obinna Igwebuike",
      image: obinna,
      url: "https://www.linkedin.com/in/obinnaigwebuike/",
      title: "Co-Founder, Commercial Development Lead, kliqr",
    },
    {
      name: "Onuoha Anayor Daniel",
      image: daniel,
      url: "https://www.linkedin.com/in/anayor-daniel/",
      title: "Technical Recruiter, Rova",
    },
    {
      name: "Olumide Gbadebo",
      image: gbadebo,
      url: "http://linkedin.com/in/olumide-gbadebo-7292a098/",
      title: "CEO, Adunni Organics",
    },
  ];

  //const team = [
  //  {
  //    name: "Anna Onaye Ututu",
  //    image: anna,
  //    url: "https://www.linkedin.com/in/h",
  //    title: "President, Graduatepro Career Network",
  //  },
  //  {
  //    name: "Shigaba Victor",
  //    image: victor,
  //    url: "https://www.linkedin.com/in/h",
  //    title: "Tech Services (Software Development/DevOps)",
  //  },
  //  {
  //    name: "Sodiq Saidi",
  //    image: sodiq,
  //    url: "https://www.linkedin.com/in/h",
  //    title: "Tech Assistant (Intern)",
  //  },
  //  {
  //    name: "Peace Nduagu",
  //    image: peace,
  //    url: "https://www.linkedin.com/in/h",
  //    title: "Growth Assistant (Intern)",
  //  },
  //  //{
  //  //  name: "Paul  Otu",
  //  //  image: "./assets/Logos/gradprowhite.png",
  //  //  url: "https://www.linkedin.com/in/h",
  //  //  title: "Design Services (Illustrations/Graphics)",
  //  //},
  //  {
  //    name: "Emmanuel  Lazarus",
  //    image: emmanuel,
  //    url: "https://www.linkedin.com/in/h",
  //    title: "Management Associate",
  //  },
  //  //{
  //  //  name: "Chizaram Nwankwo",
  //  //  image: "./assets/Logos/gradprowhite.png",
  //  //  url: "https://www.linkedin.com/in/h",
  //  //  title: "Customer Care Assistant",
  //  //}
  //];

  return (
    <section className={`pt-5 pb-5 ${styles.peopleCon}`} id="people">
      <div className="container p-4">
        <div className="row align-items-center justify-content-center">
          <div className={`col-md-12 pb-5 mb-4 ${styles.peopleHead}`}>
            <svg
              className="position-absolute"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0705 21.4499C9.0984 20.7356 6.23627 20.7823 3.22162 20.6967C2.63287 20.6801 2.1434 21.132 2.12567 21.7057C2.10794 22.2796 2.57256 22.7586 3.16131 22.7756C6.01991 22.8567 8.74017 22.7912 11.5633 23.4691C12.1343 23.6064 12.7124 23.265 12.8507 22.7077C12.9926 22.1507 12.6415 21.5868 12.0705 21.4499Z"
                fill="#161B23"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.8309 15.3815C13.2983 10.5355 7.46761 5.96945 1.58016 2.77801C1.0659 2.4997 0.416831 2.68098 0.133099 3.18285C-0.150634 3.68471 0.0338085 4.31828 0.548072 4.59659C6.24045 7.68266 11.8797 12.0986 16.2598 16.7846C16.657 17.2081 17.3309 17.2375 17.7636 16.8504C18.1963 16.4633 18.2282 15.8051 17.8309 15.3815Z"
                fill="#161B23"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.7834 14.1539C23.5318 9.72171 22.255 5.15326 20.4498 1.12307C20.2121 0.597288 19.5844 0.357438 19.0488 0.58723C18.5097 0.817368 18.265 1.43119 18.4991 1.95698C20.1447 5.62741 21.3648 9.77855 20.6838 13.8153C20.5881 14.3817 20.9818 14.9172 21.5599 15.0104C22.138 15.104 22.6877 14.7199 22.7834 14.1539Z"
                fill="#161B23"
              />
            </svg>
            <h2>People</h2>
          </div>
          <div className={`col-md-11 ${styles.facultyCon}`}>
            <div className="row">
              <div className="col-md-4">
                <div className={`row ${styles.faculty}`}>
                  <section className={`col-12 ${styles.imgCon}`}></section>
                  <div className="col-12 pt-3">
                    <h4>Naomi Lucas</h4>
                  </div>
                  <div className="col-12">
                    <p>Program Director</p>
                  </div>
                </div>
              </div>
              <div className="col-md-8 h-100">
                <div
                  className={`row d-flex align-self-center ${styles.facultyInfo}`}
                >
                  <div className="col-md-12">
                    <p>
                      Naomi is a dynamic leader in Media, Youth, and Technology,
                      renowned for her impactful initiatives worldwide. As the
                      founder of Graduatepro Early Careers Accelerator and
                      International Youth Development Centre, she's committed to
                      enhancing economic opportunities for youth. With accolades
                      from esteemed institutions like the University of
                      Cambridge and the University of Oxford, Naomi leverages
                      her expertise to bridge the gap between talent demand and
                      job availability, driven by her strategic mindset and
                      extensive network.
                    </p>
                  </div>
                  <div className="col-md-3 d-flex">
                    <div>
                      <a
                        href="https://www.facebook.com/Nayomie"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="ms-2 rounded-circle bg-black d-flex justify-content-center align-items-center"
                          style={{ width: "50px", height: "50px" }}
                        >
                          <FaFacebook />
                        </div>
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.linkedin.com/in/naomilucas/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="ms-2 rounded-circle bg-black d-flex justify-content-center align-items-center"
                          style={{ width: "50px", height: "50px" }}
                        >
                          <FaLinkedinIn />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className={`row`}>
              <div className={`col-md-12 p-5  ${styles.assessors}`}>
                <svg
                  className="position-absolute"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0705 21.4499C9.0984 20.7356 6.23627 20.7823 3.22162 20.6967C2.63287 20.6801 2.1434 21.132 2.12567 21.7057C2.10794 22.2796 2.57256 22.7586 3.16131 22.7756C6.01991 22.8567 8.74017 22.7912 11.5633 23.4691C12.1343 23.6064 12.7124 23.265 12.8507 22.7077C12.9926 22.1507 12.6415 21.5868 12.0705 21.4499Z"
                    fill="#161B23"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.8309 15.3815C13.2983 10.5355 7.46761 5.96945 1.58016 2.77801C1.0659 2.4997 0.416831 2.68098 0.133099 3.18285C-0.150634 3.68471 0.0338085 4.31828 0.548072 4.59659C6.24045 7.68266 11.8797 12.0986 16.2598 16.7846C16.657 17.2081 17.3309 17.2375 17.7636 16.8504C18.1963 16.4633 18.2282 15.8051 17.8309 15.3815Z"
                    fill="#161B23"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.7834 14.1539C23.5318 9.72171 22.255 5.15326 20.4498 1.12307C20.2121 0.597288 19.5844 0.357438 19.0488 0.58723C18.5097 0.817368 18.265 1.43119 18.4991 1.95698C20.1447 5.62741 21.3648 9.77855 20.6838 13.8153C20.5881 14.3817 20.9818 14.9172 21.5599 15.0104C22.138 15.104 22.6877 14.7199 22.7834 14.1539Z"
                    fill="#161B23"
                  />
                </svg>
                <h2>Faculty</h2>
              </div>
              <div className="col-md-12 position-relative">
                <div className={styles.sliderArrow}>
                  <button
                    className={styles.arrow1}
                    onClick={prevFacilitatorSlide}
                  >
                    <FaArrowLeftLong />
                  </button>
                  <button
                    className={styles.arrow2}
                    onClick={nextFacilitatorSlide}
                  >
                    <FaArrowRightLong />
                  </button>
                </div>
                <div className={`row ${styles.card}`}>
                  <Slider {...settings} ref={facilitatorSliderRef}>
                    {faculty.map((f, index) => (
                      <div
                        className="container col-md-2"
                        style={{ width: "15rem", borderRadius: "16px" }}
                        key={index}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <img
                              src={f.image}
                              alt={f.name}
                              className="img-fluid"
                              style={{ borderRadius: "16px" }}
                            />
                          </div>
                          <div className="col-md-12 pt-3">
                            <h4>{f.name}</h4>
                          </div>
                          <div className="col-md-12">
                            <p>{f.title}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>

              {/*<div className="col-md-12">
                <div className="row">
                  <div className={`"col-md-12 p-5 ${styles.assessors}`}>
                    <h2>Team</h2>
                  </div>
                  <div className="col-md-12 position-relative">
                    <div className={styles.sliderArrow}>
                      <button className={styles.arrow1} onClick={prevAssessorSlide}>
                        <FaArrowLeftLong />
                      </button>
                      <button className={styles.arrow2} onClick={nextAssessorSlide}>
                        <FaArrowRightLong />
                      </button>
                    </div>
                    <div className={`row ${styles.card}`}>
                      <Slider {...settings} ref={assessorSliderRef}>
                        {team.map((t, index) => (
                          <div className="container col-md-2" style={{width: "15rem", borderRadius: "16px"}} key={index}>
                            <div className="row">
                              <div className="col-md-12">
                                <img src={t.image} alt={t.name} className='img-fluid' style={{borderRadius: "16px"}} />
                              </div>
                              <div className="col-md-12 pt-3">
                                <h4>{t.name}</h4>
                              </div>
                              <div className="col-md-12">
                                <p>{t.title}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default People;
