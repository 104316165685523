import React, { useState } from 'react'
import PageNav from './Navbar/Navbar'
import Hero from './Hero/Hero'
import Featured from './Featured/Featured'
import Footer from './Footer/Footer'
import Impact from './Impact/Impact'
import OurService from './OurServices/OurServices'
import People from './People/People'
import Solutions from './Solutions/Solutions'
import Support from './Support/Support'
import Testimonials from './Testimonials/Testimonials'
import CardHero from './CardHero/CardHero'
import styles from './RootComponent.module.css'

const RootComponent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
        <PageNav className={styles.nav} handleToggleClick={handleToggleClick} isMenuOpen={isMenuOpen} />
        <div className={`${isMenuOpen ? styles.blur : ''}`}>
            <CardHero />
            <Hero />
            <Impact />
            <Solutions />
            <OurService />
            <People />
            <Featured />
            <Testimonials />
            <Support />
            <Footer />
        </div>
    </div>
  )
}

export default RootComponent