import React from "react";
import { FaLinkedinIn, FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./Support.module.css";

const Support = () => {
  return (
    <section className={styles.support} id="support">
      <div className="container p-4" id="support">
        <div className="row align-items-center d-flex justify-content-around">
          <div className={`"col-md-12 p-5 ${styles.supportHead}`}>
            <svg
              className="position-absolute"
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0705 21.4499C9.0984 20.7356 6.23627 20.7823 3.22162 20.6967C2.63287 20.6801 2.1434 21.132 2.12567 21.7057C2.10794 22.2796 2.57256 22.7586 3.16131 22.7756C6.01991 22.8567 8.74017 22.7912 11.5633 23.4691C12.1343 23.6064 12.7124 23.265 12.8507 22.7077C12.9926 22.1507 12.6415 21.5868 12.0705 21.4499Z"
                fill="#161B23"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.8309 15.3815C13.2983 10.5355 7.46761 5.96945 1.58016 2.77801C1.0659 2.4997 0.416831 2.68098 0.133099 3.18285C-0.150634 3.68471 0.0338085 4.31828 0.548072 4.59659C6.24045 7.68266 11.8797 12.0986 16.2598 16.7846C16.657 17.2081 17.3309 17.2375 17.7636 16.8504C18.1963 16.4633 18.2282 15.8051 17.8309 15.3815Z"
                fill="#161B23"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.7834 14.1539C23.5318 9.72171 22.255 5.15326 20.4498 1.12307C20.2121 0.597288 19.5844 0.357438 19.0488 0.58723C18.5097 0.817368 18.265 1.43119 18.4991 1.95698C20.1447 5.62741 21.3648 9.77855 20.6838 13.8153C20.5881 14.3817 20.9818 14.9172 21.5599 15.0104C22.138 15.104 22.6877 14.7199 22.7834 14.1539Z"
                fill="#161B23"
              />
            </svg>
            <h2>Support</h2>
          </div>
          <div className="col-md-8 pb-5">
            <div className="row d-flex align-items-center justify-content-around text-center">
              <div className={`col-sm-6 mb-3 mb-sm-0`}>
                <Link
                  to={"https://paystack.com/pay/gof1"}
                  class={styles.icon_button}
                >
                  {/*<div class={styles.icon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g clip-path="url(#clip0_4564_14493)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15 9L9 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15 15V9H9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_4564_14493">
                                      <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>*/}
                  <span class={styles.button_text}>make a donation</span>
                </Link>
              </div>
              <div className={`col-sm-6 mb-3 mb-sm-0`}>
                <Link to={"/partner"} class={styles.icon_button}>
                  {/*<div class={styles.icon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g clip-path="url(#clip0_4564_14493)">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15 9L9 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M15 15V9H9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_4564_14493">
                                      <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>*/}
                  <span class={styles.button_text}>partner</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-5">
            <div className="row d-flex align-items-center justify-content-around">
              <div className={`col-md-9 ${styles.line} d-none d-lg-block`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="860"
                  height="2"
                  viewBox="0 0 988 2"
                  fill="none"
                >
                  <path d="M0 1H988" stroke="#AF7D17" stroke-width="0.4" />
                </svg>
              </div>
              <div
                className={`col-md-2 justify-content-center d-flex ${styles.socials}`}
              >
                <a
                  href="https://www.facebook.com/NaomiLucasCo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="ms-2 rounded-circle d-flex justify-content-center align-items-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "2px solid #000",
                    }}
                  >
                    <FaFacebook />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/naomi-lucas"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="ms-2 rounded-circle d-flex justify-content-center align-items-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "2px solid #000",
                    }}
                  >
                    <FaLinkedinIn />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/naomilucasco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="ms-2 rounded-circle d-flex justify-content-center align-items-center"
                    style={{
                      width: "50px",
                      height: "50px",
                      border: "2px solid #000",
                    }}
                  >
                    <FaInstagram />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
