import React from "react";
import styles from "./CardHero.module.css";
import fund from '../../Assets/Logos/fundIcon1.svg'
import teb from '../../Assets/Logos/tebIcon1.svg'
import network from '../../Assets/Logos/networkIcon1.svg'
import job from '../../Assets/Logos/jobclubIcon1.svg'
import { Link } from "react-router-dom";

const CardHero = () => {
  const scrollToSection = (id) => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({behavior: "smooth"});
    }
  };
  
  return (
    <section className={`${styles.cardHero}`}>
        <div className="container px-4">
            <div className="row align-items-center d-flex justify-content-around">
                <div className="col-md-3 col-6 border-start border-end">
                    <div className="row">
                        <Link onClick={() => scrollToSection("teb")}>
                        <div className="col-md-12 text-center pt-3">
                        <img src={teb} alt="teb" width={40} />
                        </div>
                        <div className={`col-md-12 text-center pt-3 ${styles.cardHead1}`}>
                            <p>The Employment Bootcamp</p>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-3 col-6 border-start border-end">
                    <div className="row">
                        <Link
                          onClick={() => scrollToSection("solutions")}
                        >
                        <div className="col-md-12 text-center pt-3">
                        <img src={fund} alt="fund" width={40} />
                        </div>
                        <div className={`col-md-12 text-center pt-3 ${styles.cardHead1}`}>
                            <p>Opportunity Fund</p>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-3 col-6 text-center border-start border-end">
                    <div className="row">
                        <Link onClick={() => scrollToSection("job-club")}>
                        <div className="col-md-12 text-center pt-3">
                        <img src={job} alt="job" width={40} />
                        </div>
                          <div className={`col-md-12 text-center pt-3 ${styles.cardHead1}`}>
                                  <p>Job Club</p>
                          </div>
                        </Link>
                    </div>
                </div>
                <div className="col-md-3 col-6 border-start border-end">
                    <div className="row">
                    <Link onClick={() => scrollToSection("network")}>
                        <div className="col-md-12 text-center pt-3">
                        <img src={network} alt="network" width={40} />
                        </div>
                        <div className={`col-md-12 text-center pt-3 ${styles.cardHead1}`}>
                            <p>Network</p>
                        </div>
                    </Link>
                    </div>
                </div>
            </div>
          </div>
    </section>
  );
};

export default CardHero;
