import React from 'react'
import styles from "./Featured.module.css"
import featured from '../../Assets/featured/Untitled.png'
import featured1 from '../../Assets/featured/Untitled3.png'
import featured2 from '../../Assets/featured/Untitled1.png'
import featured3 from '../../Assets/featured/Untitled2.png'
import featured4 from '../../Assets/featured/Untitled16.png'
import featured5 from '../../Assets/featured/Untitled4.png'
import featured6 from '../../Assets/featured/Untitled5.png'
import featured7 from '../../Assets/featured/Untitled6.png'
import featured8 from '../../Assets/featured/Untitled7.png'
import featured9 from '../../Assets/featured/Untitled8.png'
import featured10 from '../../Assets/featured/Untitled9.png'
import featured11 from '../../Assets/featured/Untitled10.png'
import featured12 from '../../Assets/featured/Untitled11.png'
import featured13 from '../../Assets/featured/Untitled12.png'
import featured14 from '../../Assets/featured/Untitled13.png'
import featured15 from '../../Assets/featured/Untitled14.png'
import featured16 from '../../Assets/featured/Untitled15.png'

const Featured = () => {
  return (
    <section id='featured'>
        <div className="container p-4">
            <div className="row">
                <div className={`"col-md-12 p-5 position-relative ${styles.featured}`}>
                    <svg
                    className='position-absolute'
                    xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9295 20.9499C13.9016 20.2356 16.7637 20.2823 19.7784 20.1967C20.3671 20.1801 20.8566 20.632 20.8743 21.2057C20.8921 21.7796 20.4274 22.2586 19.8387 22.2756C16.9801 22.3567 14.2598 22.2912 11.4367 22.9691C10.8657 23.1064 10.2876 22.765 10.1493 22.2077C10.0074 21.6507 10.3585 21.0868 10.9295 20.9499Z" fill="#161B23"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.16912 14.8815C9.70172 10.0355 15.5324 5.46945 21.4198 2.27801C21.9341 1.9997 22.5832 2.18098 22.8669 2.68285C23.1506 3.18471 22.9662 3.81828 22.4519 4.09659C16.7596 7.18266 11.1203 11.5986 6.74022 16.2846C6.34302 16.7081 5.66912 16.7375 5.23642 16.3504C4.80372 15.9633 4.77182 15.3051 5.16912 14.8815Z" fill="#161B23"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.216616 13.6539C-0.531784 9.22171 0.745014 4.65326 2.55021 0.62307C2.78791 0.0972884 3.41562 -0.142562 3.95122 0.0872304C4.49032 0.317368 4.73502 0.93119 4.50092 1.45698C2.85532 5.12741 1.63522 9.27855 2.31622 13.3153C2.41191 13.8817 2.01822 14.4172 1.44011 14.5104C0.862015 14.604 0.312315 14.2199 0.216616 13.6539Z" fill="#161B23"/>
                    </svg>
                    <h2>Featured</h2>
                </div>
                <div className="col-md-12 pb-5 px-4">
                    <div className="row">
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured1} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured2} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured3} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured4} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured5} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured6} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured7} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured8} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured9} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured10} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured11} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured12} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured13} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured14} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured15} className="img-fluid" alt="featured logo" />
                      </div>
                      <div className="col-3 col-sm-3 col-md-3 col-lg-2 p-0 m-0">
                        <img src={featured16} className="img-fluid" alt="featured logo" />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Featured