import React from 'react'
import styles from './Footer.module.css'
import graduatepro from '../../Assets/Logos/graduate-pro-logo-black.svg'

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className="container p-4">
            <div className="row">
                <div className="col-md-4">
                    <div className={`row ${styles.firstColumn}`}>
                        <div className="col-md-12">
                            <img src={graduatepro} alt="graduate pro" width={208} />
                        </div>
                        <div className="col-md-12">
                            <p>Graduatepro is an Early Career Accelerator bridging the gap between the demand for work-ready talent and the supply of decent, meaningful jobs.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={`row text-center ${styles.secondColumn}`}>
                        <div className="col-md-12">
                            <a target="_blank" rel="noreferrer" href="https://www.app.naomilucas.xyz">
                                Shop
                            </a>
                        </div>
                        <div className="col-md-12">
                            <a target='_blank' rel="noreferrer" href="https://www.naomilucas.xyz/privacy-policy">Privacy Policy</a>
                        </div>
                        <div className="col-md-12">
                            <a target='_blank' rel="noreferrer" href="https://www.naomilucas.xyz/terms-of-service">Terms of Service</a>
                        </div>
                        <div className="col-md-12">
                            <a target="_blank" rel="noreferrer" href="https://naomilucas.xyz">
                                Visit Naomi Lucas
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={`row ${styles.thirdColumn}`}>
                        <div className="col-md-12 text-md-end text-center">
                            <span>&copy; 2024 Naomi Lucas</span>
                        </div>
                        <div className="col-md-12 text-md-end text-center">
                            <span>All rights reserved</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer