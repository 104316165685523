import React, { useEffect, useState, useMemo } from 'react'
import threeBdy from '../../Assets/Background/ThreeBodyObject.svg'
import threeBdyMob from '../../Assets/Background/threeBdyMob.svg'
import styles from './Hero.module.css'

const Hero = () => {
    const heroTheme = useMemo(() => [
        {
            header: "A Perfect Storm:",
            bodytext: [
                "263 million young people will lack an economic stake in the system by 2025.",
                "15m+ new jobs needed yearly.",
                "67% of today’s workforce are not engaged at work."
            ]
        },
        {
            header: "A Broken System:",
            bodytext: [
                "40m+ MSMEs in Nigeria alone. 98.8% are micro and small businesses.",
                "Hyper-focus on job, instead of opportunity creation.",
                "Very few places to get timely and relevant career support."
            ]
        },
        {
            header: "A Golden Opportunity:",
            bodytext: [
                "To help young Africans find clarity. Clarity ignites passion, focus, speed and a clear sense of direction.",
                "To reimagine decent jobs by teaching young people agency and how to create their own opportunities.",
                "Young people must be encouraged to find their purpose, connect to problems they are passionate about and ship work that matters."
            ]
        }
    ], []);

    const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);
    const [currentBodyIndex, setCurrentBodyIndex] = useState(0);

    useEffect(() => {
        const bodyTextInterval = setInterval(() => {
            setCurrentBodyIndex((prevIndex) => {
                const nextBodyIndex = prevIndex + 1;
                if (nextBodyIndex < heroTheme[currentHeaderIndex].bodytext.length) {
                    return nextBodyIndex;
                } else {
                    if (currentHeaderIndex < heroTheme.length - 1) {
                        setCurrentHeaderIndex((prevHeaderIndex) => prevHeaderIndex + 1);
                    } else {
                        setCurrentHeaderIndex(0);
                    }
                    return 0;
                }
            });
        }, 2000); // Adjust interval time as needed

        return () => clearInterval(bodyTextInterval);
    }, [currentHeaderIndex, heroTheme]);

    return (
        <>
            <section className={`h-100 ${styles.heroCon}`} id='home'>
                <div className="container px-4">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="row align-items-center">
                                <div className={`col-md-12 ${styles.themeHead}`}>
                                    <h2>{heroTheme[currentHeaderIndex]?.header}</h2>
                                </div>
                                <div className={`col-md-12 ${styles.bodyText}`}>
                                    <p>{heroTheme[currentHeaderIndex]?.bodytext[currentBodyIndex]}</p>
                                </div>
                            </div>
                        </div>
                        <div className={`col-md-6 float-end ${styles.heroImg}`}>
                            <img src={threeBdy} alt="graduates" className='d-none d-lg-block img-fluid' />
                            <img src={threeBdyMob} alt="graduates" className='d-lg-none img-fluid pt-5' width={375} />
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${styles.aboutCon}`} id='about'>
                <div className="container px-4">
                    <div className="row">
                        <div className={`col-12 ${styles.aboutText}`}>
                            <p>
                                Graduatepro is an early career accelerator bridging the gap between the demand for work-ready talent and the supply of 
                                decent, meaningful jobs.
                                <br /><br />
                                We believe in young people and their ability to create their own opportunities if given the tools, resources and support they need and as a collective, we remain committed to finding new ways to reach and connect them to life-changing opportunities.
                            </p>    
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero;
