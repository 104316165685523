import React, { useEffect, useState } from "react";
import graduatePro from "../../Assets/Logos/gradprowhite.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import styles from "./Navbar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PageNav = ({ handleToggleClick, isMenuOpen }) => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const router = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    handleToggleClick();
  };

  const scrollToSection = (id) => {
    if (location.pathname === "/") {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      router("/");
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`text-center sticky-top ${styles.navCon} ${
        scrolled ? styles.scrolled : styles.scrolled
      } ${isMenuOpen ? styles.open : ""}`}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          as="button"
          className={`navbar-toggler shadow-none bx-menu-button text-white ${
            styles.navMobBtn
          } ${scrolled ? "text-white" : ""}`}
          onClick={handleClick}
          style={{ border: "none" }}
        >
          {isMenuOpen ? <IoClose /> : <GiHamburgerMenu />}
        </Navbar.Toggle>
        <Navbar.Brand>
          <Link to={"/"}>
            <img
              src={scrolled ? graduatePro : graduatePro}
              alt="graduatepro logo"
              className={`img-fluid ${styles.imgCol}`}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={`justify-content-md-end ${styles.navColl}  ${
            isMenuOpen ? styles.open1 : ""
          }`}
        >
          <Nav>
            <Nav.Link>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
                onClick={() => scrollToSection("about")}
              >
                About
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
                onClick={() => scrollToSection("solutions")}
              >
                Solutions
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
                onClick={() => scrollToSection("impact")}
              >
                Impact
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
                onClick={() => scrollToSection("people")}
              >
                People
              </Link>
            </Nav.Link>
            <Nav.Link className={`d-none d-lg-block`}>
              <Link
                to={"/"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLinkBtn} ${
                  scrolled ? styles.navLinkBtnScrolled : ""
                }`}
                onClick={() => scrollToSection("support")}
              >
                Support
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                to={"/contact"}
                style={{ textDecoration: "none" }}
                className={`${styles.navLink} ${
                  scrolled ? styles.navLinkScrolled : ""
                }`}
              >
                Contact
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default PageNav;
